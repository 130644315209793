import React from 'react'

import {Swiper , SwiperSlide} from "swiper/react"

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import './clientSection.css'

import C1 from '../homePageAssets/c1.webp'
import easol from '../homePageAssets/easol_logo.webp'
import takelessons from '../homePageAssets/c3.webp'
import creatd from '../homePageAssets/creatd2.png'
import eventure from '../homePageAssets/evenNew.webp'


// import required modules
import { Pagination } from 'swiper'

export default function App() {
  return (
    <> 
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >

       <div className="py-5 d-flex align-items-center">
          <SwiperSlide> <div className=" client_logo my-5"> <a href="" target="_blank" ><img src={C1} className='w-100' alt="" /></a></div> </SwiperSlide>
          <SwiperSlide> <div className=" client_logo my-5"> <a href='https://www.eventuresworldwide.com/' target="_blank" ><img src={eventure} className='w-100' alt="" /></a> </div> </SwiperSlide>
          {/* <SwiperSlide> <div className=" client_logo my-5"> <a href='https://easol.com/' target="_blank" ><img src={easol} className='w-100' alt="" /></a> </div> </SwiperSlide> */}
          <SwiperSlide> <div className=" client_logo my-5"> <a href='https://creatd.com/'target="_blank" ><img src={creatd} className='w-100' alt="" /></a> </div> </SwiperSlide>
          <SwiperSlide> <div className=" client_logo my-5"> <a href='https://takelessons.com/'target="_blank" ><img src={takelessons} className='w-100' alt="" /></a> </div> </SwiperSlide>  
       </div>

      </Swiper>
    </>
  );
}
