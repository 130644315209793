import React from "react";
import '../ourCasesSection.css'
import case_1 from '../assets/s1.png'

function Case1(){
    return(
        <div className="row py-5 w-100 m-0 col_reverse">
            

            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center">
                <div className='AboutInfo_box'>
                    <span className='span_Titles'>Web Development</span>
                    <div className="bar rounded"></div>
                    <h2 className='sub_titles titleFont_style pt-3'>Satsang Adventures</h2>
                    <p className='text_p'>The Satsang Adventures is one of the leading projects of Elite Techlogix. It is one of the leading multi-purpose 
                        CMS themes that has been developed to increase your conversion with the help of our smart ad spaces and SEO-friendly layout. Improved Earnings
                        Satsang Adventures gives you robust ad management which allows you to earn more money in a less period of time. Satsang Adventures essentially 
                        is the % of ads that are clicked per impression. 
                    </p>

                    <div className="caseList">
                        <ul className="all_type_list p-0">
                            <li className="casesList py-1"><i class="fa-solid fa-bezier-curve text_gradient"></i> UI/UX Design </li>
                            <li className="casesList py-1"><i class="fa-solid fa-laptop-code text_gradient"></i> Website Development </li>
                            <li className="casesList py-1"><i class="fa-solid fa-shield-halved text_gradient"></i> Quality Assurance </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
                <div className='service_imgBox'> 
                    <img src={case_1} className='w-100' alt=""  /> 
                </div>
            </div>

        </div>
    )
}

export default Case1;