import React from "react";
import '../ourCasesSection.css'
import case_3 from '../assets/polaranalytics_logo.jpg'

function Case3(){
    return(
         <div className="row py-5 w-100 m-0 col_reverse">

            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center">
                <div className='AboutInfo_box'>
                    <span className='span_Titles'>Lead Generation</span>
                    <div className="bar rounded"></div>
                    <h2 className='sub_titles titleFont_style pt-3'>Polar Analytics</h2>
                    <p className='text_p'>Polar Analytics is one of our top clients from the UK. We have been working with them for 4 years now. 
                        We have taken care of all of their Lead Generation tasks where they provided us with the name of the company or the criteria to search 
                        for new leads.  We provide them with a Contact Person, Verified email, Office number, LinkedIn, Company Website, Direct Dial, etc. We 
                        have generated thousands of data for them and still continue to do so.
                    </p>

                    <div className="caseList">
                        <ul className="all_type_list p-0">
                            <li className="casesList py-1"><i class="fa-solid fa-database  text_gradient"></i> Data Scrapping </li>
                            <li className="casesList py-1"><i class="fa-solid fa-list-check  text_gradient"></i> Manage Listings </li>
                            <li className="casesList py-1"><i class="fa-solid fa-envelope-circle-check  text_gradient"></i> Email Enrichment </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className="col-lg-6 col-md-12 justify-content-center d-flex align-items-center">
                <div className='service_imgBox'> 
                    <img src={case_3} className='w-100' alt="" /> 
                </div>
            </div>

            </div>
    )
}

export default Case3;