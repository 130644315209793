import React from "react";
import {Link} from 'react-router-dom'

function LeadGeneration(){
    return(
        <div>
            <div className="container">
                <div className="row p-4 g-4">

                    <div className="col-lg-4">
                        {/* <Link to="/project-detail/Easol">
                            <div className="servicesBox portfolioBox easol rounded-4 ">
                                <div className="portFolio_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                                    <h2 className='small_titles titleFont_style text-light'>EASOL</h2>                       
                                </div>
                            </div>
                        </Link> */}
                    </div>

                </div>
            </div>

        </div>
    )
}

export default LeadGeneration;