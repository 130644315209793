import React from "react";
import {Link} from 'react-router-dom'

function Websites(){
    return(
        <div className="container">
        <div className="row p-4 g-4">

            <div className="col-lg-4">
                <Link to='/project-detail/Try Furly'>
                    <div className="servicesBox portfolioBox furly rounded-4 ">
                        <div className="portFolio_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                            <h2 className='small_titles titleFont_style text-light'>TRY FURLY</h2>                       
                        </div>
                    </div>
                </Link>
            </div>

            {/* <div className="col-lg-4">
                <Link to='/project-detail/Satsung Adventure'>
                    <div className="servicesBox portfolioBox satsung_adv rounded-4 ">
                        <div className="portFolio_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                            <h2 className='small_titles titleFont_style text-light'>SATSUNG ADVENTURE</h2>                       
                        </div>
                    </div>
                </Link>
            </div> */}
 
{/*         <div className="col-lg-4">
                <Link to='/project-detail/Dino Kingdom Returns'>
                    <div className="servicesBox portfolioBox dino_bg rounded-4 ">
                        <div className="portFolio_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                            <h2 className='small_titles titleFont_style text-light'>Dino Kingdom Returns</h2>                       
                        </div>
                    </div>
                </Link>
            </div> */}

            {/* <div className="col-lg-4">
                <Link to='/project-detail/Aspire Adventures'>
                    <div className="servicesBox portfolioBox aspire_bg rounded-4 ">
                        <div className="portFolio_overLay rounded d-flex flex-column justify-content-center align-items-center px-3">
                            <h2 className='small_titles titleFont_style text-light'>Aspire Adventures</h2>                       
                        </div>
                    </div>
                </Link>
            </div> */}

        </div>
    </div>
    )
}

export default Websites;