import React from "react";
import './header.css'
import LogoImg from '../assets/logoWhite.webp'
import {Nav,Navbar,NavDropdown} from "react-bootstrap";

import { useEffect } from "react";

function Header(){

  useEffect(() => {
    document.addEventListener("scroll", () => {
      let header = document.querySelector(".NavigationBar");
      let navBtn = document.querySelector(".navBtn-scroll")
      if (window.scrollY > 200) {
        header?.classList.add("scrolled");
        navBtn?.classList.add('navBtn-scroll')
      } else {
        header?.classList.remove("scrolled");
        navBtn?.classList.remove('navBtn-scroll')
      }
    });
  }, []);

  

    return(
        <div>
          <Navbar expand="lg" className="NavigationBar">
    
          <Navbar.Brand href="/" className="px-2">
            <div className="LogoBox margin"><img src={LogoImg} className='w-100' alt="" /></div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="nav_list">

            <Nav className="ms-auto">
            <Nav.Link className="pe-3" href="/">Home</Nav.Link>

              <NavDropdown title="SERVICES" to = "/services" id="basic-nav-dropdown">
                <div className="dropMenu1">
                  <NavDropdown.Item className="dropMenuList py-3" href="/services/#lead">Lead Generation</NavDropdown.Item>
                  <NavDropdown.Item className="dropMenuList py-3" href="/services/#web-development">Web Development</NavDropdown.Item>
                  <NavDropdown.Item className="dropMenuList py-3" href="/services/#Mob-development"> Mobile App Development</NavDropdown.Item>
                  <NavDropdown.Item className="dropMenuList py-3" href="/services/#UI_design">UI/UX Design</NavDropdown.Item>
                  <NavDropdown.Item className="dropMenuList py-3" href="/services/#digi_mark">Digital Marketing</NavDropdown.Item>
                  <NavDropdown.Item className="dropMenuList py-3" href="/services/#call_center">Call Center</NavDropdown.Item>
                </div>
              </NavDropdown>

              {/* <Nav.Link className="pe-3" href="/easol-partner">EASOL PARTNER </Nav.Link> */}
              <Nav.Link className="pe-3" href="/work">WORK </Nav.Link>

            <NavDropdown title="COMPANY" id="basic-nav-dropdown">
              <div className="dropMenu1">
                <NavDropdown.Item className="dropMenuList py-3" href="/about-us">About Us</NavDropdown.Item>
                <NavDropdown.Item className="dropMenuList py-3" href="/career"> Career</NavDropdown.Item>
                <NavDropdown.Item className="dropMenuList py-3" href="life-at-elite-techlogix">Life at Elite Techlogix</NavDropdown.Item>
                </div>
            </NavDropdown>

            <Nav.Link className="pe-3" href="/contact">CONTACT US</Nav.Link>
          </Nav>

          <form class="d-flex margin">
            <a href="mailto:Info@elitetechlogix.com" target="_blank" className="btn navBtn rounded" type="submit" >Schedule A Call <i class="fa-solid fa-arrow-right ms-2"></i> </a>
        </form>

      </Navbar.Collapse>
        
          </Navbar>


        </div>
    )
}

export default Header;